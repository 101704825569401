import type { ExecutableUIFunctions, WorkerFunctionBroadcastMessage, ExecutableWorkerFunctionsImplementation, ExecutableWorkerFunctions } from "./types.ts";

/**
 * Message channel on the worker thread
 */
export class WorkerMessageChannel {
    private _port: MessagePort;
    /** Executable functions by the broadcast channel */
    private _functions: ExecutableWorkerFunctionsImplementation;
    /** Callbacks store */
    private _callbacks: Record<string, Function> = {};

    id: string;

    get initialized() {
        return this._port != null;
    }

    constructor(pOptions: {
        id: string,
        functions: ExecutableWorkerFunctionsImplementation
        port: MessagePort
    }) {
        this.id = pOptions.id;
        this._port = pOptions.port;
        this._functions = pOptions.functions;

        this._port.onmessage = this._onMessage.bind(this);
        this._port.postMessage({
            operation: 'connected'
        });
    }

    /**
     * Will broadcast function execution on the channel
     * @param {string} pName Name of the function to execute on the receiver
     * @param {string} pArgument Optional argument that will be passed to the executed function. Cannot contain class instances or functions.
     * @param {number} pTimeout The timeout after which the promise will reject if no response is received
     * @param {AbortSignal} pAbortSignal - Abort signal used to abort pending callback. Will also message the target port that hte operation is to be aborted
     */
    async execute<K extends keyof ExecutableUIFunctions>(pName: K, pArgument: ExecutableUIFunctions[K]['argument'], pTimeout: number = 10_000, pAbortSignal?: AbortSignal): Promise<ExecutableUIFunctions[K]['result']> {
        const uid = crypto.randomUUID();
        const messageObject: WorkerFunctionBroadcastMessage<ExecutableUIFunctions> = {
            operation: 'execute',
            name: pName,
            payload: pArgument,
            meta: {
                uid: uid,
            }
        };
        let promiseRes: Function;
        let promiseRej: Function;
        const promise = new Promise<any>((res, rej) => {
            promiseRes = res;
            promiseRej = rej;
        });
        let timeoutDebounce: number | undefined = undefined;
        if (pAbortSignal) {
            const handleAbort = () => {
                if (timeoutDebounce) { clearTimeout(timeoutDebounce); }
                pAbortSignal.removeEventListener('abort', handleAbort);
                delete this._callbacks[uid];
                const abortMessage: WorkerFunctionBroadcastMessage<ExecutableUIFunctions> = {
                    operation: 'abort',
                    name: pName,
                    meta: {
                        uid: uid
                    }
                };

                this._port.postMessage(abortMessage);
                promiseRej(new Error('Worker function aborted'));
            };
            pAbortSignal.addEventListener('abort', handleAbort);
        }
        if (pTimeout !== -1) {
            timeoutDebounce = setTimeout(() => {
                delete this._callbacks[uid];
                promiseRej(new Error('Worker Channel timeout'));
            }, pTimeout);
        }
        this._callbacks[uid] = (success?: boolean, result?: string) => {
            if (timeoutDebounce) {
                clearTimeout(timeoutDebounce);
            }
            delete this._callbacks[uid];
            if (!success) {
                promiseRej(new Error(result ?? 'Recieved failed status'));
            } else {
                promiseRes(result);
            }
        };
        this._port.postMessage(messageObject);
        return promise;
    }


    private async _onMessage(e: MessageEvent) {
        const message = e.data;
        if (!message) {
            console.warn('Worker received empty message', message);
            return;
        }
        const messageObject = message as WorkerFunctionBroadcastMessage<ExecutableWorkerFunctions>;
        switch (messageObject.operation) {
            case 'execute':
                let responseObject = {
                    operation: 'callback',
                    meta: {
                        uid: messageObject.meta.uid,
                    }
                } as WorkerFunctionBroadcastMessage<ExecutableWorkerFunctions>;
                if (messageObject.name && typeof this._functions[messageObject.name] === 'function') {
                    let result: any;
                    let success: boolean;
                    try {
                        result = await this._functions[messageObject.name](messageObject.payload);
                        success = true;
                    } catch (ex: any) {
                        result = ex?.message ?? ex?.error ?? ex;
                        success = false;
                    }
                    responseObject.payload = result;
                    responseObject.success = success;
                    this._port?.postMessage(responseObject);
                } else {
                    responseObject.payload = `Could not execute function with name: ${messageObject.name}`;
                    responseObject.success = false;
                    this._port?.postMessage(responseObject);
                }
                break;
            case 'callback':
                const uid = messageObject.meta.uid;
                const callback = this._callbacks[uid];
                if (typeof callback === 'function') {
                    callback(messageObject.success, messageObject.payload);
                }
                break;
            case 'abort':
                break;
        }
    }

}
